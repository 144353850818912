import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height fill-width d-flex flex-column pa-0",attrs:{"fluid":""}},[_c(VContainer,{staticClass:"d-flex align-center justify-center pa-0 overflow-auto",attrs:{"fluid":""}},[_c(VLayout,{staticClass:"pa-0 overflow-auto",staticStyle:{"max-height":"calc(100vh - 64px) !important"},attrs:{"child-flex":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.displayExportableConsolidationRecords,"hide-default-footer":"","disable-pagination":"","sort-by":"period.value","sort-desc":""},scopedSlots:_vm._u([{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.period.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.generatingReport == item.id)?_c(VProgressCircular,{staticClass:"mr-2",attrs:{"indeterminate":"","color":"grey","size":"15","width":"2"}}):_vm._e(),(!item.workingHoursReportPresent && _vm.generatingReport != item.id)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.generateReport(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-outline ")])]}}],null,true)},[_c('span',[_vm._v("Genera Report")])]):_vm._e(),(item.workingHoursReportPresent)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.exportReportConfirmation(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])]}}],null,true)},[_c('span',[_vm._v("Esporta Report")])]):_vm._e()]}}])})],1),_c('ConfirmDialog',{attrs:{"dialog":_vm.confirmDialogShow,"dialogText":_vm.confirmDialogText,"dialogSubtext":_vm.confirmDialogSubtext},on:{"c-dialog-confirm":function($event){return _vm.exportReport()},"c-dialog-cancel":function($event){_vm.confirmDialogShow = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }