<template>
   <v-container class="fill-height fill-width d-flex flex-column pa-0" fluid>
    <v-container fluid class="d-flex align-center justify-center pa-0 overflow-auto">
        <v-layout child-flex class="pa-0 overflow-auto" style="max-height: calc(100vh - 64px) !important">
        <v-data-table
            :headers="headers"
            :items="displayExportableConsolidationRecords"
            hide-default-footer
            disable-pagination
            sort-by="period.value"
            sort-desc
        >
            <template v-slot:item.period="{ item }">
                {{item.period.name}}
            </template>
            <template v-slot:item.actions="{ item }">
                <!-- <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="recordExport(item)"
                        >
                            mdi-file-chart
                        </v-icon>
                    </template>
                    <span>Esporta Report</span>
                </v-tooltip> -->
                <v-progress-circular
                    indeterminate
                    color="grey"
                    size="15"
                    width="2"
                    class="mr-2"
                    v-if="generatingReport == item.id"
                ></v-progress-circular>
                <v-tooltip left v-if="!item.workingHoursReportPresent && generatingReport != item.id">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="generateReport(item)"
                        >
                            mdi-file-outline
                        </v-icon>
                    </template>
                    <span>Genera Report</span>
                </v-tooltip>
                <v-tooltip left v-if="item.workingHoursReportPresent">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            color="green"
                            v-on="on"
                            @click="exportReportConfirmation(item)"
                        >
                            mdi-download
                        </v-icon>
                    </template>
                    <span>Esporta Report</span>
                </v-tooltip>
                
            </template>
        
        </v-data-table>
    </v-layout>
        <ConfirmDialog
            :dialog="confirmDialogShow"
            :dialogText="confirmDialogText"
            :dialogSubtext="confirmDialogSubtext"
            v-on:c-dialog-confirm="exportReport()"
            v-on:c-dialog-cancel="confirmDialogShow = false"
        />
       
    </v-container>
    </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
export const ConfirmDialog = () => import('../../global/ConfirmDialog.vue');

export default {
    data: function() {
        return {
            headers: [
                { text: 'Azienda', align: 'start', value: 'company.companyName'},
                { text: 'Periodo', align: 'start', value: 'period', sort: (a, b) => {return (a.value.diff(b.value, ['months']))['months']} },
                { text: '', value: 'actions', sortable: false, align: 'end' },
            ],
            generatingReport: null,
            confirmDialogShow: false,
            confirmDialogText: "",
            confirmDialogSubtext: "",
            selectedItem: null,
        }
    },
    components: {
        ConfirmDialog
    },
    props: {
        exportableConsolidationRecords: {
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    watch: {
        exportableConsolidationRecords: {
            handler: function(val) {
                this.generatingReport = null;
            },
            deep: true
        }
    },
    methods: {
        exportReportConfirmation: function(item) {
            this.confirmDialogText = "Esportazione Report Orari";
            this.confirmDialogSubtext = "Il file d'esportazione verrà inviato all'indirizzo " + this.currentUserEmail;
            this.selectedItem = item;
            this.confirmDialogShow = true;
        },
        exportReport: function() {
            this.$emit('working-hours-report-export', this.selectedItem.company.companyName, this.selectedItem.month, this.selectedItem.year);
            this.confirmDialogShow = false;
        },
        generateReport: function(item) {
            this.generatingReport = item.id;
            this.$emit('working-hours-report-generate', item.company.companyName, item.month, item.year);
        }
    },
    computed: {
        ...mapState({
            currentUserEmail: state => state.currentUser.email
        }),
        displayExportableConsolidationRecords: function() {
            return this.exportableConsolidationRecords.map(record => {
                let dateObject = {
                    day: 1,
                    month: record.month,
                    year: record.year
                };
                record.id = record.company.companyName + DateTime.fromObject(dateObject).setLocale('it').toFormat('-MM-yyyy');
                const periodValue = DateTime.fromObject(dateObject).setLocale('it');
                const periodName = periodValue.toFormat('MMMM yyyy');
                record.period = {
                    name: periodName,
                    value: periodValue
                };

                return record;
            });
        }
    }
}
</script>
